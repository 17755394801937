@tailwind base;
@tailwind components;
@tailwind utilities;

body, #root {
    width: 100vw;
    height: 100vh;
}


.ais-RefinementList ul {
    @apply flex font-normal text-sm flex-wrap gap-2 my-4;
}

.ais-RefinementList ul li {
    @apply rounded bg-purple-200 px-2 py-1;
}

.ais-RefinementList ul input {
    display: none;
}

.ais-RefinementList-labelText {
    @apply font-medium text-purple-900;
}

.ais-RefinementList-count {
    @apply font-black text-white p-1 px-1.5 bg-purple-400 rounded-full;
}

.ais-RefinementList-item--selected {
    @apply border-2 border-purple-800;
}

